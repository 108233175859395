import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme';
import { DashboardScreen } from './components/dashboard';

export const App = () => {
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Routes>
          <Route path="/" element={<DashboardScreen />} />
          {/* <Route path="/segments" element={<SegmentsScreen />} /> */}
        </Routes>
      </ChakraProvider>
    </BrowserRouter>
  );
};
