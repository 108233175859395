import { useNavigate } from 'react-router-dom';
import { Center, Container, Image, Text } from '@chakra-ui/react';
import logo from '../../logo.png';

export const PageContainer = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();

  return (
    <Container maxW="1100px" paddingTop="20" paddingBottom="15">
      <Center>
        <Image width={378} src={logo} onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
      </Center>
      <Center></Center>
      {children}
    </Container>
  );
};
