import React from 'react';
import { PageContainer } from '../page-container';

export const DashboardScreen = () => {
  return (
    <PageContainer>
      <></>
    </PageContainer>
  );
};
